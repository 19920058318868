<template>
  <section id="dashboard-analytics">
    <b-col class="content-header-left mb-1" cols="12" md="12">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">CRM</h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb style="font-size: 0.9rem">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="14"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item active v-if="admin" > REPORTS </b-breadcrumb-item>
              <b-breadcrumb-item v-else to="/reports">REPORTS  </b-breadcrumb-item>
              <b-breadcrumb-item active> CRM </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-row class="match-height">
      <b-col md="3" cols="6" xl="2" v-for="data in modules" :key="data.id">
        <statistic-card-vertical
          :icon="data.icon"
          :statistic="data.title"
          color="danger"
          :route="data.route"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    StatisticCardVertical,
    BRow,
    BCol,

    // InvoiceList,
  },
  data() {
    return {
      data: [
        {
          icon: "rupee",
          title: "Expense Report",
          route: "/reports/crm/expensereport",
        },
        {
          icon: "BarChart2Icon",
          title: "Complaint Report",
          route: "/reports/crm/complaintreport",
        },
        {
          icon: "UsersIcon",
          title: "Customer Data Bank Report",
          route: "/reports/crm/customerdatabankreport",
        },
        {
          icon: "UserIcon",
          title: "Lead Report",
          route: "/reports/crm/leadreport",
        },
        {
          icon: "CompassIcon",
          title: "Followup Report",
          route: "/reports/crm/followupreport",
        },
        {
          icon: "CastIcon",
          title: "Sales Report",
          route: "/reports/crm/salesreport",
        },
      ],
      userRole:'',
      sidebarData:[],
      modules:[],
      userData: JSON.parse(localStorage.getItem("userData")),
      admin:false,

    };
  },
  mounted() {
    // const userData = JSON.parse(localStorage.getItem("UserRights"));
    // const role = JSON.parse(localStorage.getItem("userData")).role;
    // if (role !== "admin") {
    //   this.$router.push("/");
    // }
    this.userRole = this.userData.role;
    this.sidebarData = JSON.parse(localStorage.getItem("sidebarData"));
    this.admin= (this.userRole == 'admin')
    if(!this.admin){
      this.setRoute();
    }
  },
  methods: {
    // kFormatter
    setRoute () {
      this.sidebarData.map((item) => {
        if (item.title == 'Reports') {
          item.children.map((child)=>{
            if(child.title == 'CRM'){
              this.modules = child.children
            }
          })
        }
      })
      this.modules.map((item) => {
        this.data.map((inner) => {
          if (inner.title == item.title) {
            item.icon = inner.icon
            item.route = inner.route
          }
        })
      })
    }
  }
};
</script>
